import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { OuvrageIncorporationDigesteur } from 'shared-ration/models/ouvrage-incorporation-digesteur';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class OuvrageIncorporationDigesteurService {

  

  constructor(private http: HttpClient) { }

  save(organisation: string,site: string, ouvrage: OuvrageIncorporationDigesteur): Observable<OuvrageIncorporationDigesteur> {
    const path = `${environment.api}/organisations/${organisation}/sites/${site}/ouvrage-digesteurs`;
    return this.http.post<OuvrageIncorporationDigesteur>(path, ouvrage, httpOptions);
  }

  bulk(organisation: string,site: string, ouvrages: OuvrageIncorporationDigesteur[]): Observable<OuvrageIncorporationDigesteur[]> {
    const path = `${environment.api}/organisations/${organisation}/sites/${site}/ouvrage-digesteurs/bulk`;
    return this.http.post<OuvrageIncorporationDigesteur[]>(path, ouvrages, httpOptions);
  }

  bySite(organisation: string,site: string): Observable<OuvrageIncorporationDigesteur[]> {
    const path = `${environment.api}/organisations/${organisation}/sites/${site}/ouvrage-digesteurs`;
    return this.http.get<OuvrageIncorporationDigesteur[]>(path, httpOptions);
  }

  bydigesteur(organisation: string,site: string,digesteur: string): Observable<OuvrageIncorporationDigesteur[]> {
    const path = `${environment.api}/organisations/${organisation}/sites/${site}/ouvrage-digesteurs/digesteurs/${digesteur}`;
    return this.http.get<OuvrageIncorporationDigesteur[]>(path, httpOptions);
  }


  byOuvrage(organisation: string,site: string,ouvrage: string): Observable<OuvrageIncorporationDigesteur[]> {
    const path = `${environment.api}/organisations/${organisation}/sites/${site}/ouvrage-digesteurs/ouvrages/${ouvrage}`;
    return this.http.get<OuvrageIncorporationDigesteur[]>(path, httpOptions);
  }

  delete(organisation: string,site: string,id: string): Observable<string> {
    const path = `${environment.api}/organisations/${organisation}/sites/${site}/ouvrage-digesteurs/${id}`;
    return this.http.delete<string>(path, httpOptions);
  }
}
