import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from 'environments/environment';
import * as lodash from 'lodash';

import { Ration } from 'shared-ration/models/ration';
import { Incorporation } from 'shared-ration/models/incorporation';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-type': 'application/json'
  })
};


@Injectable({
  providedIn: 'root'
})
export class IncorporationService {

  constructor(
    private http: HttpClient
  ) { }

  get(organisation: string, site: string, debut: string, fin: string): Observable<Ration>{
    const path = `${environment.api}/organisations/${organisation}/sites/${site}/rations/from/${debut}/to/${fin}`;
    return this.http.get<Ration>(path)
      .pipe(
        map(data => {
          data.ouvrages = data.ouvrages.map((ouvrage:any) => {
            ouvrage.chargements = ouvrage.chargements.map((charge:any) => {
              delete charge.stockEtat;
              charge.quantite = charge.quantite ? parseFloat(charge.quantite.toFixed(2)) : charge.quantite;
              charge.consigne = charge.consigne ? parseFloat(charge.consigne.toFixed(2)) : charge.consigne;
              return charge;
            })
            ouvrage.receptions = ouvrage.receptions.map((recep:any) => {
              delete recep.stockEtat;
              recep.quantite = recep.quantite ? parseFloat(recep.quantite.toFixed(2)) : recep.quantite;
              return recep;
            })
            ouvrage.incorporations = ouvrage.incorporations.map((inc:any) => {
              inc.quantite = inc.quantite ? parseFloat(inc.quantite.toFixed(2)) : inc.quantite;
              inc.consigne = inc.consigne ? parseFloat(inc.consigne.toFixed(2)) : inc.consigne;
              return inc;
            })
            return ouvrage;
          })
          return data;
        })
      );
  }

  getIncorporations(organisation: string, site: string, debut: string, fin: string): Observable<any>{
    const path = `${environment.api}/organisations/${organisation}/sites/${site}/rations/incorporation/from/${debut}/to/${fin}`;
    return this.http.get<any>(path)
      .pipe(
        map(incorporations => {
          incorporations = incorporations.map((inc:any) => {
            inc.quantite = inc.quantite ? parseFloat(inc.quantite.toFixed(2)) : inc.quantite;
            inc.consigne = inc.consigne ? parseFloat(inc.consigne.toFixed(2)) : inc.consigne;
            return inc;
          })
          return lodash.sortBy(incorporations, 'date');
        })
      );
  }

  getChargements(organisation: string, site: string, debut: string, fin: string): Observable<any>{
    const path = `${environment.api}/organisations/${organisation}/sites/${site}/rations/chargement/from/${debut}/to/${fin}`;
    return this.http.get<any>(path)
      .pipe(
        map(chargements => {
          chargements = chargements.map((inc:any) => {
            inc.quantite = inc.quantite ? parseFloat(inc.quantite.toFixed(2)) : inc.quantite;
            inc.consigne = inc.consigne ? parseFloat(inc.consigne.toFixed(2)) : inc.consigne;
            return inc;
          })
          return lodash.sortBy(chargements, 'date');
        })
      );
  }

  update(organisation: string, site: string,ration: Ration): Observable<Ration> {
    const path = `${environment.api}/organisations/${organisation}/sites/${site}/rations`;
    return this.http.put<Ration>(path, ration, httpOptions);
  }

  save(organisation: string, site: string,ration: Ration) {
    const path = `${environment.api}/organisations/${organisation}/sites/${site}/rations`;
    return this.http.post<Ration>(path, ration, httpOptions);
  }

  saveChargements(organisation: string, site: string,ration: Ration) {
    const path = `${environment.api}/organisations/${organisation}/sites/${site}/rations/chargements`;
    return this.http.post<Ration>(path, ration, httpOptions);
  }

  saveIncorporations(organisation: string, site: string,ration: Ration) {
    const path = `${environment.api}/organisations/${organisation}/sites/${site}/rations/incorporations`;
    return this.http.post<Ration>(path, ration, httpOptions);
  }


  bilan(organisation: string, site: string,debut: string,fin: string) {
    const path = `${environment.api}/organisations/${organisation}/sites/${site}/rations/bilan?debut=${debut}&fin=${fin}`;
    return this.http.get<any[]>(path);
  }


  fromReception(organisation: string, site: string,reception: any): Observable<Incorporation>{
    delete reception.site.organisation;
    const path = `${environment.api}/organisations/${organisation}/sites/${site}/rations/incorporations/receptions`;
    return this.http.post<Incorporation>(path, reception);
  }

  fromOuvrageReception(organisation: string, site: string,reception: any): Observable<any>{
    const path = `${environment.api}/organisations/${organisation}/sites/${site}/rations/receptions`;
    return this.http.post<any>(path, reception);
  }

  delete(ration: Ration) {

  }

}
