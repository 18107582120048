import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ParametresService } from 'global/services/parametres/parametres.service';
import { environment } from 'environments/environment';
import { ParametresRation } from 'shared-ration/models/parametres-ration';
import { Indicateur } from 'shared-ration/models/indicateur';

import * as _ from 'lodash';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class ParametresRationService {

  constructor(private http: HttpClient, private parametresService: ParametresService) { }

  save(organisation: string,site: string, parametre: ParametresRation): Promise<ParametresRation> {
    const path = `${environment.api}/organisations/${organisation}/sites/${site}/rations/parametres`;
    return this.http.post<ParametresRation>(path, parametre, httpOptions)
      .toPromise()
      .then(res => {
        let parametres = this.parametresService.getParametres();
        let parametresRation: any = {
          id: res.id,
          revision: res.revision,
          indicateurs: []
        };
        parametres.indicateursRation.forEach(indicateur => {
          let indic =  {};
          _.merge(indic, indicateur);
          parametresRation.indicateurs.push(indic);
        })
        _.merge(parametresRation, res);
        parametresRation.site = res.site;
        return parametresRation;
      });
  }

  bySite(organisation: string,site: string): Promise<ParametresRation> {
    const path = `${environment.api}/organisations/${organisation}/sites/${site}/rations/parametres`;
    return this.http.get<ParametresRation>(path, httpOptions)
      .toPromise()
      .then(res => {
        let parametres = this.parametresService.getParametres();
        let parametresRation: any = {
          id: res.id,
          revision: res.revision,
          indicateurs: []
        };
        parametres.indicateursRation.forEach(indicateur => {
          let indic =  {};
          _.merge(indic, indicateur);
          parametresRation.indicateurs.push(indic);
        })
        parametresRation.indicateurs = _.values(_.merge(
          _.keyBy(parametresRation.indicateurs, 'id'),
          _.keyBy(res.indicateurs, 'id')
        ));
        parametresRation.site = res.site;
        return parametresRation;
      })
      .catch(error => {
        let parametres = this.parametresService.getParametres();
        let parametresRation: any = {
          indicateurs: []
        };
        parametres.indicateursRation.forEach(indicateur => {
          let indic =  {};
          _.merge(indic, indicateur);
          parametresRation.indicateurs.push(indic);
        })
        parametresRation.site = site;
        return parametresRation;
      });
  }

}

